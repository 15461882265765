import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import meetSmart from '../icons/meetsmart.webp'

function InternsPresentPastMeetSmart() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 10000, // Adjust the speed as per your requirement 2000 miliseconds
  };

  return (
    <div className="testimonials">
        <div className="step-edu-specialist-main">
          <div className="slide-content-spec">
            <div className="logo-block-spec"><img className="gridlogo-spec" src={meetSmart} alt="Document Accuracy"></img></div>
            <div className="text-block-spec">                          
                      <ul>
                          <h4><strong>Other AI Application</strong> </h4>
                          <br />  {/* This will add a blank line after the heading */}
                          <h5><strong>Welcome to MinuteMint AI:</strong> Your Gateway to Enhanced Meeting Management Across All Industries</h5>
                          <br></br>
                          <h5>Revolutionize Your Meetings with MinuteMint AI</h5>
                In the modern business landscape, where meetings are the backbone of collaboration, MadasHealth.ai proudly 
                presents MinuteMint AI. This innovative solution is designed to revolutionize meeting management across
                various industries, bringing efficiency and intelligence to every discussion, whether face-to-face or 
                virtual. <br></br><br></br>
                <h5>Advanced AI for Every Meeting Scenario</h5>
                MinuteMint AI is a versatile tool, adept for face-to-face meetings, teleconferences, and videoconferencing
                platforms like Zoom or MS Teams. It uses advanced AI technology to transform the way meetings are conducted, 
                ensuring that no critical point or decision is overlooked, regardless of the meeting format. <br></br><br></br>
                <h5>Tailored for Diverse Business Needs</h5>
                Understanding the unique requirements of different sectors, MinuteMint AI provides a customizable solution to
                meeting management. Whether it's for strategic planning, creative sessions, or operational reviews, 
                MinuteMint AI is adaptable, enhancing decision-making and focus in any industry context.
              </ul>
            </div>
          </div>
        </div>               
    </div>
  );
}

export default InternsPresentPastMeetSmart;
