import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import meetSmart from '../icons/meet2.webp'

function InternsPresentPastMeetSmartTwo() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 10000, // Adjust the speed as per your requirement 2000 miliseconds
  };

  return (
    <div className="testimonials">
        <div className="step-edu-specialist-main">
          <div className="slide-content-spec">            
            <div className="text-block-spec">                          
                      <ul><h5><strong> Thank You for Trusting Madas Health AI</strong></h5>
                          
                          We appreciate your interest in exploring how our AI-driven solutions are helping businesses and professionals
                          achieve greater efficiency and success. Your partnership in this journey is invaluable to us, and we look forward
                          to continuing to support your goals with innovative, adaptive technology.
                           
                          
              </ul>
            </div>
            <div className="logo-block-spec"><img className="gridlogo-spec" src={meetSmart} alt="Document Accuracy"></img></div>
          </div>          
        </div>               
    </div>
  );
}

export default InternsPresentPastMeetSmartTwo;
